import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import RenderField from "./RenderField";
import { useCreateProject } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getPrincipleEntitiesForSelect } from "../../../../../common/utils/utils";
import { useGetAllEventsList } from "../../../../../queries/query-hooks/workspace/events-hook";
import postService from "../../../../../api/services/post-service";
import {
  ADD_PROJECT,
  UPDATE_PROJECT,
} from "../../../../../api/endpoints/channel-endpoint";
import toast from "react-hot-toast";
import useBackdrop from "../../../../../components/custom-hooks/use-backdrop";
import { useQueryClient } from "@tanstack/react-query";
import { GET_PROJECT_LIST } from "../../../../../queries/query-constants/workspace/workspace-constant";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import axiosInstance from "../../../../../api/instances/axiosInstance";
import { GET_EVENTS_SEARCH } from "../../../../../api/endpoints/events-endpoints";
import { debounce } from "lodash";
import EventUserEngagementData from "./EventUserEngagementData";
import dayjs from "dayjs";

const EventsForm = ({
  project_type_code,
  project_group_id,
  project_type_id,
  projectDetails = [],
  channelData = [],
}) => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { redirectTo } = useRedirect();
  const queryClient = useQueryClient();
  const { data: createProjectData, isLoading: principalEntitiesLoading } =
    useCreateProject();
  const [searchText, setSearchText] = useState({});
  const [forms, setForms] = useState([
    {
      title: "",
      touchpoint_target: "",
      touchpoint_sent: "",
      delivered: "",
      seen: "",
      channel: "",
      engagement_date: "",
      template: "",
    },
  ]);

  const { data: allEventsList, isLoading: isAllEventsListLoading } =
    useGetAllEventsList({ limit: 30, offset: 0 });

  const formik = useFormik({
    initialValues: {
      project_name: "",
      project_desc: "",
      principal_entity_id: "",
      ga_status: 0,
      doctor_registration: 1,
      project_attributes: "",
      project_start_date: "",
      project_end_date: "",
      project_owner: "",
      project_contact_name: "",
      project_contact_email: "",
      project_contact_phone: "",
      project_type_id: project_type_id,
      project_group_id: project_group_id,
      project_status: 1,
      project_po_ref: "",
      project_po_date: "",
      project_logo: "",
      project_impressions_target: "",
      event_banner: "",
      events: [],
      featured_events: "",
      project_to_content: [
        {
          label: "",
          content_id: "",
          content_category: "",
          view_target: 0,
          read_target: 0,
          unique_reach_target: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Project Name is required"),
      project_desc: Yup.string().required("Project Description is required"),
      principal_entity_id: Yup.string().required(
        "Principal Entity is required"
      ),
      project_start_date: Yup.date()
        .required("Start date is required")
        .test(
          "start_date_before_end_date",
          "Start date must be before the End date.",
          function (value) {
            const { project_end_date } = this.parent;
            return (
              !project_end_date ||
              !value ||
              new Date(value) < new Date(project_end_date)
            );
          }
        ),
      project_end_date: Yup.date()
        .required("End date is required")
        .test(
          "end_date_after_start_date",
          "End date must be after the Start date.",
          function (value) {
            const { project_start_date } = this.parent;
            return (
              !project_start_date ||
              !value ||
              new Date(value) > new Date(project_start_date)
            );
          }
        ),
      project_owner: Yup.string().required("Project Owner name is required"),
      project_contact_name: Yup.string().required(
        "Project contact person name is required"
      ),
      project_contact_email: Yup.string()
        .required("Project contact person Email is required")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Please enter a valid email address."
        ),
      project_contact_phone: Yup.string()
        .matches(/^[6-9]\d{9}$/, {
          message: "Please enter valid number.",
          excludeEmptyString: false,
        })
        .required("Project contact person phone is required"),
      project_po_ref: Yup.string().required("Project PO Reference is required"),
      project_po_date: Yup.string().required("Project PO Date is required"),
      project_logo: Yup.string().required("Project Logo is required"),
      project_impressions_target: Yup.number()
        .required("Project Impressions Target is required")
        .moreThan(0, "Please enter valid Impression target."),
      event_banner: Yup.string().required("Event Banner is required"),
      events: Yup.array().min(1, "At least one Event is required"),
      featured_events: Yup.string().required("Featured Event is required"),
    }),
    onSubmit: async (values) => {
      const payload = { ...values };
      const events = values.events.map((event) =>
        event.value ? JSON.parse(event.value) : null
      );
      const featuredEvents = JSON.parse(
        values.featured_events ? values.featured_events : null
      );

      payload.project_to_event = events.map((event) => {
        return {
          ...event,
          featured_status: event.event_id === featuredEvents.event_id ? 1 : 0,
        };
      });

      payload.project_attributes = [
        {
          key: "demographic_by_age",
          value: values.project_attributes === 1 ? "true" : "false",
        },
        {
          key: "event_thumbnail",
          value: values.event_banner || "",
        },
        {
          key: "doctor_registration",
          value: values.doctor_registration,
        },
      ];

      const isUserEngagementValid = forms.every((form) =>
        Object.values(form).every((value) => value !== "")
      );

      const nonEmptyForms = forms.filter(
        (form) => !Object.values(form).every((value) => value === "")
      );

      if (!isUserEngagementValid && nonEmptyForms.length > 0) {
        toast.error("Please fill in all fields in user engagement");
        return;
      }

      const updatedFormData = forms.map((form) => {
        return {
          ...form,
          touchpoint_target: +form.touchpoint_target,
          touchpoint_sent: +form.touchpoint_sent,
          delivered: +form.delivered,
          seen: +form.seen,
          engagement_date: dayjs(form?.engagement_date)?.format("YYYY-MM-DD"),
          event_id: featuredEvents?.event_id || null,
          project_id: projectDetails?.at(0)?.id || null,
        };
      });

      payload.user_engagement =
        nonEmptyForms.length > 0 ? [...updatedFormData] : [];

      payload.project_to_content = [];

      delete payload.events;
      delete payload.featured_events;
      delete payload.event_banner;
      delete payload.project_engagement_tracking;

      try {
        showBackdrop();

        let response;
        if (projectDetails.length > 0) {
          response = await postService(UPDATE_PROJECT, payload);
        } else {
          response = await postService(ADD_PROJECT, payload);
        }

        if (response?.data?.success) {
          queryClient.invalidateQueries({
            queryKey: [GET_PROJECT_LIST],
          });
          toast.success("Project created successfully");
          redirectTo("workspace/clirnet");
        } else {
          toast.error("Something went wrong");
        }
      } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
      } finally {
        hideBackdrop();
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
  } = formik;

  useEffect(() => {
    setSelectedEvents(values.events);
  }, [values.events]);

  useEffect(() => {
    if (projectDetails.length > 0) {
      preFillProjectForm();
    }
  }, []);

  function preFillProjectForm() {
    const projectImpressionsTarget =
      projectDetails?.at(0)?.project_target?.impressions_target || 0;

    const projectToContent =
      projectDetails?.at(0)?.project_to_event?.map((item) => {
        return {
          label: item.event_name,
          value: JSON.stringify({
            id: item.id,
            event_name: item.event_name,
            event_id: item.event_id,
            event_type: item.event_type,
            event_start_date: item.event_start_date,
            event_end_date: item.event_end_date,
          }),
        };
      }) || [];

    const featuredEvents =
      projectDetails
        ?.at(0)
        ?.project_to_event?.filter((item) => item?.is_featured === 1)
        ?.map((el) =>
          JSON.stringify({
            id: el.id,
            event_name: el.event_name,
            event_id: el.event_id,
            event_type: el.event_type,
            event_start_date: el.event_start_date,
            event_end_date: el.event_end_date,
          })
        )
        ?.toString() || {};

    const userEngagement =
      projectDetails?.at(0)?.project_engagement_tracking?.map((item) => {
        return {
          title: item?.title,
          channel: item?.channel_id?.toString(),
          engagement_date: item?.engagement_date,
          template: item?.template,
          touchpoint_target: item?.touchpoint_target?.toString(),
          touchpoint_sent: item?.touchpoint_sent?.toString(),
          delivered: item?.delivered?.toString(),
          seen: item?.seen?.toString(),
        };
      }) || [];

    const eventBanner =
      projectDetails
        ?.at(0)
        ?.project_attributes?.find(
          (item) => item.attribute_name === "event_thumbnail"
        )?.attribute_value || "";

    const projectAttributesValue =
      projectDetails
        ?.at(0)
        ?.project_attributes?.find(
          (item) => item.attribute_name === "demographic_by_age"
        )?.attribute_value || "false";

    const doctorRegistration =
      projectDetails
        ?.at(0)
        ?.project_attributes?.find(
          (item) => item.attribute_name === "doctor_registration"
        )?.attribute_value === "0"
        ? 0
        : 1;

    setValues({
      ...values,
      ...projectDetails?.at(0),
      events: projectToContent,
      featured_events: featuredEvents,
      project_attributes: projectAttributesValue === "true" ? 1 : 0,
      event_banner: eventBanner,
      doctor_registration: doctorRegistration,
      project_impressions_target: projectImpressionsTarget,
    });

    if (userEngagement?.length > 0) {
      setForms(userEngagement);
    }
  }

  const fields = [
    {
      id: 1,
      label: "Project Name",
      field_name: "project_name",
      type: "text",
      placeholder: "Enter Project Name",
      required: true,
    },
    {
      id: 2,
      label: "Project Description",
      field_name: "project_desc",
      type: "textarea",
      placeholder: "Enter Project Description",
      required: true,
    },
    {
      id: 3,
      label: "Principal Entity",
      field_name: "principal_entity_id",
      type: "select",
      options: getPrincipleEntitiesForSelect(
        createProjectData?.principal_entities || []
      ),
      required: true,
      optionLabelKey: "principal_entity_name",
      optionValueKey: "principal_entity_id",
    },
    {
      id: 4,
      label: "GA Status",
      field_name: "ga_status",
      type: "switch",
      required: false,
    },
    {
      id: 5,
      label: "Show doctor registration number",
      field_name: "doctor_registration",
      type: "switch",
      required: false,
    },
    {
      id: 6,
      label: "Show demographic with age and gender",
      field_name: "project_attributes",
      type: "switch",
      required: false,
    },
    {
      id: 7,
      label: "Project Start Date",
      field_name: "project_start_date",
      type: "date",
      required: true,
    },
    {
      id: 8,
      label: "Project End Date",
      field_name: "project_end_date",
      type: "date",
      required: true,
    },
    {
      id: 9,
      label: "Project Owner Name",
      field_name: "project_owner",
      type: "text",
      placeholder: "Enter Project Owner",
      required: true,
    },
    {
      id: 10,
      label: "Project Contact Person Name",
      field_name: "project_contact_name",
      type: "text",
      placeholder: "Enter Owner Contact",
      required: true,
    },
    {
      id: 11,
      label: "Project Contant Person Email",
      field_name: "project_contact_email",
      type: "text",
      placeholder: "Enter Owner Email",
      required: true,
    },
    {
      id: 12,
      label: "Project Contact Person Phone",
      field_name: "project_contact_phone",
      type: "number",
      placeholder: "Enter Owner Phone",
      required: true,
    },
    {
      id: 13,
      label: "Purchase Order Reference",
      field_name: "project_po_ref",
      type: "text",
      placeholder: "Enter Purchase Order Reference",
      required: true,
    },
    {
      id: 14,
      label: "Purchase Order Date",
      field_name: "project_po_date",
      type: "date",
      placeholder: "Enter Purchase Order Date",
      required: true,
    },
    {
      id: 15,
      label: "Project Logo",
      field_name: "project_logo",
      type: "file",
      required: true,
    },
    {
      id: 16,
      label: "Project Impressions Target",
      field_name: "project_impressions_target",
      placeholder: "Enter Project Impressions Target",
      type: "number",
      required: true,
    },
    {
      id: 17,
      label: "Event Banner",
      field_name: "event_banner",
      type: "file",
      required: true,
    },
    {
      id: 18,
      label: "Events",
      field_name: "events",
      type: "multiselect",
      isLoading: isAllEventsListLoading,
      placeholder: "Select multiple events",
      options: allEventsList?.map((item) => {
        return {
          label: `${item.event_name} - ${item.event_id}`,
          value: JSON.stringify({
            event_name: item.event_name,
            event_id: item.event_id,
            event_type: item.event_type,
            event_start_date: item.event_start_date,
            event_end_date: item.event_end_date,
          }),
        };
      }),
      required: true,
    },
    {
      id: 19,
      label: "Featured Events",
      field_name: "featured_events",
      type: "select",
      placeholder: "Select your featured events",
      options: selectedEvents?.map((item) => item),
      required: true,
    },
  ];

  const _loadOptions = async (inputValue) => {
    const payload = {
      limit: 10,
      offset: 0,
      search_key: inputValue,
    };

    try {
      return new Promise(async (resolve) => {
        const response = await axiosInstance.post(GET_EVENTS_SEARCH, payload);

        if (response.data.success) {
          const options = response.data.data.map((item) => ({
            label: `${item.event_name} - ${item.event_id}`,
            value: JSON.stringify({
              event_name: item.event_name,
              event_id: item.event_id,
              event_type: item.event_type,
              event_start_date: item.event_start_date,
              event_end_date: item.event_end_date,
            }),
          }));

          resolve(options);
        } else {
          console.error("Failed to load events", response.data.message);
        }
      });
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {fields.map((field) => (
          <RenderField
            {...field}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            key={field.id}
            searchText={searchText}
            loadOptions={debounce((inputText, callback) => {
              setSearchText(inputText);
              _loadOptions(inputText)
                .then((options) => {
                  console.log("check fetched data", options);
                  callback(options);
                })
                .catch((error) => {
                  console.log(error);
                });
            }, 500)}
            setFieldValue={setFieldValue}
          />
        ))}

        {projectDetails.length > 0 && (
          <div>
            <EventUserEngagementData
              channelData={channelData}
              forms={forms}
              setForms={setForms}
            />
          </div>
        )}

        <Button type="submit" className="me-4">{`${
          projectDetails.length > 0 ? "Update" : "Submit"
        }`}</Button>

        <Button
          variant="secondary"
          onClick={() => redirectTo("workspace/clirnet")}
        >
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default EventsForm;
