import React from "react";
import { Form, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import toast from "react-hot-toast";
import dayjs from "dayjs";

const EventUserEngagementData = ({ channelData = [], forms, setForms }) => {
  const handleInputChange = (index, field, value) => {
    const formattedValue =
      field === "engagement_date" && value === "" ? "" : value;
    const newForms = [...forms];
    newForms[index][field] = formattedValue;
    setForms(newForms);
  };

  const addForm = () => {
    const lastForm = forms[forms.length - 1];
    const isFormComplete = Object.values(lastForm).every(
      (value) => value?.trim() !== ""
    );

    if (!isFormComplete) {
      toast.error("Please fill in all fields for user engagement.");
      return;
    }
    setForms([
      ...forms,
      {
        title: "",
        touchpoint_target: "",
        touchpoint_sent: "",
        delivered: "",
        seen: "",
        channel: "",
        engagement_date: "",
        template: "",
      },
    ]);
  };

  const removeForm = (index) => {
    const newForms = forms.filter((_, i) => i !== index);
    setForms(newForms);
  };

  return (
    <div>
      <p>User Engagement</p>
      {forms?.map((form, index) => (
        <Form key={index} className="mb-4 p-3 border border-1 rounded">
          {/* Title */}
          <Row className="mb-3">
            <Col>
              <FloatingLabel
                controlId={`textInput_${index}`}
                label={
                  <>
                    {"Title"} {<span className="text-danger">*</span>}
                  </>
                }
                className="mb-3 custom-form-floating"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={form.title}
                  onChange={(e) =>
                    handleInputChange(index, "title", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>

          {/*Touchpoint Target*/}
          <Row className="mb-3">
            <Col>
              <FloatingLabel
                controlId={`touchpointTarget_${index}`}
                label={
                  <>
                    {"Touchpoint Target"}{" "}
                    {<span className="text-danger">*</span>}
                  </>
                }
                className="mb-3 custom-form-floating"
              >
                <Form.Control
                  type="number"
                  placeholder="Touchpoint Target"
                  value={form.touchpoint_target}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "touchpoint_target",
                      e.target.value
                    )
                  }
                />
              </FloatingLabel>
            </Col>
            {/* Touchpoint Given*/}
            <Col>
              <FloatingLabel
                controlId={`touchpointGiven_${index}`}
                label={
                  <>
                    {"Touchpoint Given"}{" "}
                    {<span className="text-danger">*</span>}
                  </>
                }
                className="mb-3 custom-form-floating"
              >
                <Form.Control
                  type="number"
                  placeholder="Touchpoint Given"
                  value={form.touchpoint_sent}
                  onChange={(e) =>
                    handleInputChange(index, "touchpoint_sent", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            {/* Delivered */}
            <Col>
              <FloatingLabel
                controlId={`delivered_${index}`}
                label={
                  <>
                    {"Delivered"} {<span className="text-danger">*</span>}
                  </>
                }
                className="mb-3 custom-form-floating"
              >
                <Form.Control
                  type="number"
                  placeholder="Delivered"
                  value={form.delivered}
                  onChange={(e) =>
                    handleInputChange(index, "delivered", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            {/* Seen */}
            <Col>
              <FloatingLabel
                controlId={`seen_${index}`}
                label={
                  <>
                    {"Seen"} {<span className="text-danger">*</span>}
                  </>
                }
                className="mb-3 custom-form-floating"
              >
                <Form.Control
                  type="number"
                  placeholder="Seen"
                  value={form.seen}
                  onChange={(e) =>
                    handleInputChange(index, "seen", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>

          {/* Channel */}
          <Row className="mb-3">
            <Col>
              <FloatingLabel
                controlId={`dropdown_${index}`}
                className="mb-3 custom-form-floating"
                label={
                  <>
                    {"Channel"} {<span className="text-danger">*</span>}
                  </>
                }
              >
                <Form.Select
                  value={form.channel}
                  onChange={(e) =>
                    handleInputChange(index, "channel", e.target.value)
                  }
                >
                  <option value="">Select a channel</option>
                  {channelData?.map((item) => (
                    <option value={item?.channel_id}>
                      {item?.channel_name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            {/* Engagement Date */}
            <Col>
              <FloatingLabel
                controlId={`dateInput_${index}`}
                label={
                  <>
                    {"Engagement Date"} {<span className="text-danger">*</span>}
                  </>
                }
                className="mb-3 custom-form-floating"
              >
                <Form.Control
                  type="date"
                  value={dayjs(form.engagement_date).format("YYYY-MM-DD") || ""}
                  onChange={(e) =>
                    handleInputChange(index, "engagement_date", e.target.value)
                  }
                  onBlur={(e) =>
                    handleInputChange(index, "engagement_date", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>

          {/* Template */}
          <Row className="mb-3">
            <Col>
              <FloatingLabel
                controlId={`textarea_${index}`}
                label={
                  <>
                    {"Template"} {<span className="text-danger">*</span>}
                  </>
                }
                className="mb-3 custom-form-floating"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Template"
                  value={form.template}
                  onChange={(e) =>
                    handleInputChange(index, "template", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>

          {/* Add/Remove Buttons */}
          <Row>
            <Col className="d-flex justify-content-end gap-3">
              {forms.length > 1 && (
                <Button
                  variant="outline-primary"
                  className="border-dashed"
                  onClick={() => removeForm(index)}
                >
                  -
                </Button>
              )}
              <Button
                variant="outline-primary"
                className="border-dashed"
                onClick={addForm}
              >
                +
              </Button>
            </Col>
          </Row>
        </Form>
      ))}
    </div>
  );
};

export default EventUserEngagementData;
