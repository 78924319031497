import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../../../dashboard/icons";
import { useSelector } from "react-redux";
import { useGetChannelList } from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import ChannelListSkeleton from "../../../../../../skeleton/workspace/doctube/channel/channel-list-skeleton";
import PageNotFound from "../../../../../PageNotFound";
import ReactTable from "../../../../../../lib/table/react-table";

import ActionButtonBar from "../../../../../../components/partials/common/action-button-bar";
import ChannelCard from "../components/card/ChannelCard";
import { selectProject } from "../../../../../../store/project/project.selector";

export default function MyChannels() {
  // const appName = useSelector(SettingSelector.app_name)

  const project = useSelector(selectProject);
  const { data: channelData, isLoading: isChannelLoading } = useGetChannelList(
    0,
    10,
    1,
    project?.project_id
  );
  const [isListView, setIsListView] = useState(false);
  const { data: channels } = channelData || { data: [] };

  const { redirectTo } = useRedirect();

  // useEffect(() => {
  //   if (channels?.length == 1) {
  //     redirectTo(`channel/dashboard/${channels[0]?.content_id}`);
  //   }
  // }, [channels]);

  const channelColumns = [
    {
      Header: "Content ID",
      accessor: "content_id",
      columnId: 1,
      disableFilters: true,
    },
    {
      Header: "Channel Name",
      accessor: "channel_name",
      columnId: 2,
      disableFilters: true,
    },
    {
      Header: "Project Type",
      accessor: "project_type_name",
      columnId: 3,
      disableFilters: true,
      Cell: ({ row, value }) => value ?? "Doctube",
    },
    {
      Header: "Subscriber Count",
      accessor: "subscriber_count",
      columnId: 4,
      disableFilters: true,
    },
    {
      columnId: 5,
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row, value }) =>
        row?.content_id == 0 ? (
          <></>
        ) : (
          <ActionButtonBar
            buttons={["select"]}
            row={row}
            onSelect={(data) => {
              redirectTo(`channel/dashboard/${data?.content_id}`);
            }}
            onEdit={(data) => {
              redirectTo(`workspace/project-details/${data?.content_id}`);
            }}
          />
        ),
    },
  ];

  const onRaiseSupportTicket = (data) => {
    console.log(data);
    redirectTo("support/support-ticket");
  };
  return (
    <>
      {!isChannelLoading && channels?.length > 0 ? (
        <>
          <Row>
            <div className="d-flex justify-content-between align-items-center pb-4">
              <h4 className="m-0">Doctube Channels</h4>
              <div className="d-flex gap-3 align-items-center">
                {/* <Button variant="outline-primary rounded-5" size="md">
                  Create Project
                </Button> */}
                <Button
                  variant="outline-primary rounded-5"
                  size="md"
                  onClick={() => {
                    redirectTo("project/create-channel/" + project?.project_id);
                  }}
                >
                  Create Channel
                </Button>
                <ul
                  className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center flex-nowrap"
                  id="pills-tab"
                  role="tablist"
                >
                  <li
                    className="nav-item ms-2"
                    role="presentation"
                    title="Grid View"
                    onClick={() => setIsListView(false)}
                  >
                    <Button
                      bsPrefix={
                        "nav-link btn-sm btn-icon rounded-pill" +
                        (isListView ? "" : " active")
                      }
                      id="grid-view-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-grid-view"
                      to="#"
                      role="tab"
                      aria-controls="pills-grid-view"
                      aria-selected="true"
                    >
                      <span className="btn-inner d-inline-flex">
                        {findIcon("View Grid", "dual-tone")}
                      </span>
                    </Button>
                  </li>
                  <li
                    className="nav-item ms-2"
                    role="presentation"
                    title="List View"
                    onClick={() => setIsListView(true)}
                  >
                    <Button
                      bsPrefix={
                        "nav-link btn-sm btn-icon rounded-pill" +
                        (isListView ? " active" : "")
                      }
                      id="list-view-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-list-view"
                      to="#"
                      role="tab"
                      aria-controls="pills-list-view"
                      aria-selected="false"
                    >
                      <span className="btn-inner d-inline-flex">
                        {findIcon("Rows", "dual-tone")}
                      </span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
          <Row className="">
            {channels?.length > 0 && isListView && (
              <Card>
                <Card.Body>
                  <ReactTable
                    data={channels}
                    columns={channelColumns}
                    isPaginationShow={false}
                  />
                </Card.Body>
              </Card>
            )}

            {channels?.length > 0 &&
              !isListView &&
              channels.map((data, key) => (
                <Col md={4} lg={4} key={key}>
                  <ChannelCard
                    channel_id={data.content_id}
                    title={data.channel_name}
                    projectType={"Doctube"}
                    publishDate={data.publish_date}
                    key={key}
                    coverImage={data.chnnale_cover_image}
                  ></ChannelCard>
                </Col>
              ))}
          </Row>
        </>
      ) : isChannelLoading ? (
        <ChannelListSkeleton />
      ) : (
        <PageNotFound
          title="You dont have any channel yet"
          backgroundImage={findIcon("Support-ticket", "dual-tone", 100)}
          message="please contact support team"
          // buttonText="Raise support ticket"
          // onFallbackClick={() => { }}
        />
      )}
    </>
  );
}
