import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Navigation } from "swiper/core";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { findIcon } from "../../../../dashboard/icons";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { useState } from "react";

function CreativeDetailsSwiper({
  creatives,
  buttonText,
  handleOnclick,
  creativesList,
  activeCampaignGroupId,
  setCurrentIndex,
  showButton = true,
}) {
  const { project_id } = useParams();
  const { redirectTo } = useRedirect();

  return (
    <>
      <div className="container d-flex flex-column align-items-center p-0 p-md-4 rounded-3">
        <div className="mb-3 d-flex justify-content-end w-100">
          {showButton && (
            <Button
              variant="secondary"
              size="md"
              className="rounded-3"
              onClick={handleOnclick}
            >
              {buttonText}
            </Button>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center position-relative swiper-container w-100">
          <span
            id="swiper-button-prev1"
            className="opacity-50 z-1 ms-n3 pe-2 cursor-pointer"
            // className="swiper-button swiper-button-prev"
          >
            {findIcon("Arrow Circle Left", "outline", 36)}
          </span>
          <Swiper
            className="swiper-wrapper align-items-center creativeDetailsSwiper"
            modules={[Navigation]}
            navigation={{
              nextEl: "#swiper-button-next1",
              prevEl: "#swiper-button-prev1",
            }}
            loop="true"
            onSlideChange={(data) => setCurrentIndex(data?.activeIndex)}
          >
            {creatives?.length > 0 &&
              creatives?.map((item, index) => {
                const fileType = item?.type;
                return (
                  <>
                    <SwiperSlide
                      key={`creative-${index}`}
                      className="text-center"
                    >
                      {fileType === "video" ? (
                        <video
                          src={item?.file}
                          className="rounded-3 mw-100"
                          autoPlay
                          loop
                        />
                      ) : (
                        <img
                          src={item?.file}
                          className="bannerImage rounded-3 img-fluid mw-100"
                        />
                      )}
                    </SwiperSlide>
                  </>
                );
              })}

            {creativesList &&
              Array.isArray(creativesList) &&
              creativesList.length > 0 &&
              creativesList.map((item, index) => {
                console.log("check item", item);
                const fileType = item?.creative_data?.type;
                return (
                  <SwiperSlide
                    key={`creative-${index}`}
                    className="text-center"
                    data-custom={JSON.stringify(item)}
                  >
                    {fileType === "video" ? (
                      <video
                        src={item?.creative_data?.file}
                        className="rounded-3 mw-100"
                        autoPlay
                        loop
                      />
                    ) : (
                      <img
                        src={item?.creative_data?.file}
                        className="bannerImage rounded-3 img-fluid"
                      />
                    )}
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <span
            id="swiper-button-next1"
            className="opacity-50 z-1 me-n3 ps-2 cursor-pointer"
            // className="swiper-button swiper-button-next"
          >
            {findIcon("Arrow Circle Right", "outline", 36)}
          </span>
        </div>
      </div>
    </>
  );
}

export default CreativeDetailsSwiper;
